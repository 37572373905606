import { useWeb3Modal } from '@web3modal/wagmi/react'
import { useAccount } from 'wagmi'
import { trimAddress } from 'config/constants/exchange'
import { Button, ButtonProps } from '@pancakeswap/uikit'

import Trans from './Trans'



const ConnectWalletButton = ({ children, ...props }: ButtonProps) => {
  const { open } = useWeb3Modal()
  const { address } = useAccount()
  const handleClick = () => {
    open()
  }

  return (

    address ? (
      <Button onClick={handleClick} {...props}>
        <div className="" style={{ color: '#000' }}>
          {children || <Trans>{trimAddress(address)}</Trans>}
        </div>
      </Button >
    ) : (
      <Button onClick={handleClick} {...props}>
        <div className="" style={{ color: '#000' }}>
          {children || <Trans>Connect Wallet</Trans>}
        </div>
      </Button>
    )
  )
}

export default ConnectWalletButton
