export const BSC_RPC_URLS = [
  'https://bsc-dataseed1.bnbchain.org',
  'https://bsc-dataseed2.bnbchain.org',
  'https://bsc-dataseed1.bnbchain.org',
]

export const BSC_TESTNET_RPC_URLS = [
  'https://data-seed-prebsc-1-s1.bnbchain.org:8545',
  'https://data-seed-prebsc-1-s2.bnbchain.org:8545',
  'https://data-seed-prebsc-2-s2.bnbchain.org:8545',
]

export const STEALTH_TESTNET_RPC_URLS = [
  'https://data-seed-prebsc-2-s1.bnbchain.org:8545',
  // 'https://test-rpc.stealthchain.org/',
  // 'https://test-rpc.stealthchain.org/',
]
