import { ReactNode, useMemo } from 'react'
import { useAccount } from 'wagmi'
import { BLOCKED_ADDRESSES } from './config/constants'
import ListsUpdater from './state/lists/updater'
import MulticallUpdater from './state/multicall/updater'
import TransactionUpdater from './state/transactions/updater'


export function Updaters() {
  return (
    <>
      <ListsUpdater />
      <TransactionUpdater />
      <MulticallUpdater />
    </>
  )
}

export function Blocklist({ children }: { children: ReactNode }) {
  const { address } = useAccount()
  const blocked: boolean = useMemo(() => Boolean(address && BLOCKED_ADDRESSES.indexOf(address) !== -1), [address])
  if (blocked) {
    return <div>Blocked address</div>
  }
  return <>{children}</>
}
