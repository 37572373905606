/* eslint-disable @typescript-eslint/no-unused-vars */

import ConnectWalletButton from 'components/ConnectWalletButton'


const UserMenu = () => {

  return (
    <ConnectWalletButton scale="sm" />
  )
}

export default UserMenu
