import { bscTokens } from './tokens'
import { Ifo } from './types'


const ifos: Ifo[] = [
  {
    id: 'peel',
    address: '0x35Bb6Dd4E8C63491057c32621c8cDdE43BabE201',
    isActive: true,
    name: 'Meta Apes ($PEEL)',
    poolBasic: {
      saleAmount: '10,000,000 PEEL',
      raiseAmount: '$400,000',
      cakeToBurn: '$0',
      distributionRatio: 0.2,
    },
    poolUnlimited: {
      saleAmount: '40,000,000 PEEL',
      raiseAmount: '$1,600,000',
      cakeToBurn: '$0',
      distributionRatio: 0.8,
    },
    currency: bscTokens.cake,
    token: bscTokens.usdt,
    releaseBlockNumber: 19964276,
    campaignId: '511300000',
    articleUrl: 'https://stealthswap.trade/voting/proposal/bafkreibomj5nilvyckdro7ztmm62syt55dcfnonxs63ji6hm2ijq35lru4',
    tokenOfferingPrice: 0.04,
    version: 3.2,
    twitterUrl: 'https://twitter.com/MetaApesGame',
    description:
      'Meta Apes is a free-to-play, play-and-earn massively multiplayer online (MMO) strategy game designed for mobile and the first game to launch on the ETH Sidechain with their own dedicated chain, Ape Chain.',
    vestingTitle: 'Utilize $PEEL for gas and governance in the Meta Apes ecosystem!',
  }
]

export default ifos
