/* eslint-disable */
import { JsonRpcProvider } from '@ethersproject/providers'
import { ChainId } from '@pancakeswap/sdk'
import { bscTest , bsc as bscMain } from 'config/constants/exchange'
import { createWeb3Modal, defaultWagmiConfig } from '@web3modal/wagmi/react'
import { bsc, bscTestnet } from 'wagmi/chains'

export const provider = (chainId) =>   {
  return new JsonRpcProvider(chainId === ChainId.BSC_TESTNET ? bscTest.rpcUrls.default : bscMain.rpcUrls.default )
}

// export const { provider, chains } = configureChains(CHAINS, [
//   jsonRpcProvider({
//     rpc: (chain) => {
//       if (chain.id === ChainId.BSC) {
//         return { http: bsc.rpcUrls.default }
//       }
//       if (chain.rpcUrls.nodeReal) {
//         return (
//           getNodeRealUrl(chain.network) || {
//             http: chain.rpcUrls.nodeReal,
//           }
//         )
//       }
//       return { http: chain.rpcUrls.default }
//     },
//   }),
// ])





const projectId = '27b9720018bf7f8740936e6a6eb28604'
const metadata = {
  name: 'Web3Modal',
  description: 'Web3Modal Example',
  url: 'https://web3modal.com',
  icons: ['https://avatars.githubusercontent.com/u/37784886']
}

const chains = [bsc, bscTestnet]
export const wagmiConfig:any = defaultWagmiConfig({ chains, projectId, metadata })


createWeb3Modal({
  wagmiConfig,
  projectId,
  chains,
  enableAnalytics: true
})