import { FAST_INTERVAL, SLOW_INTERVAL } from 'config/constants'
import useSWR, { useSWRConfig } from 'swr'
import useSWRImmutable from 'swr/immutable'
import { getChainId } from 'config/constants/exchange'
import { useNetwork } from 'wagmi'
import { provider } from 'utils/wagmi'

const REFRESH_BLOCK_INTERVAL = 6000

export const usePollBlockNumber = () => {
  const { cache, mutate } = useSWRConfig()
  const { chain } = useNetwork()
  const chainId = getChainId(chain);
  const provid = provider(chainId)

  const { data } = useSWR(
    `blockNumber-${chainId}`,
    async () => {
      const blockNumber = await provid.getBlockNumber()
      if (!cache.get(`initialBlockNumber-${chainId}`)) {
        mutate(`initialBlockNumber-${chainId}`, blockNumber)
      }
      return blockNumber
    },
    {
      refreshInterval: REFRESH_BLOCK_INTERVAL,
    },
  )

  useSWR(
    [FAST_INTERVAL, 'blockNumber', chainId],
    async () => {
      return data
    },
    {
      refreshInterval: FAST_INTERVAL,
    },
  )

  useSWR(
    [SLOW_INTERVAL, 'blockNumber', chainId],
    async () => {
      return data
    },
    {
      refreshInterval: SLOW_INTERVAL,
    },
  )
}

export const useCurrentBlock = (): number => {
  const { chain } = useNetwork()
  const chainId = getChainId(chain);
  const { data: currentBlock = 0 } = useSWRImmutable(`blockNumber-${chainId}`)
  return currentBlock
}

export const useInitialBlock = (): number => {
  const { chain } = useNetwork()
  const chainId = getChainId(chain);
  const { data: initialBlock = 0 } = useSWRImmutable(`initialBlockNumber-${chainId}`)
  return initialBlock
}
