import * as Sentry from '@sentry/react'
import { useEffect } from 'react'
import { useAccount } from 'wagmi'

function useSentryUser() {
  const { address } = useAccount()
  useEffect(() => {
    if (address) {
      Sentry.setUser({ address })
    }
  }, [address])
}

export default useSentryUser
